import React from 'react'
import { Grid } from '@mui/material'

import fato from './fats.png'

function Meme() {
  return (
    <div className='container'>
      <br/>
                       <Grid item md={12}  lg={12} xs={12} sm={12} >

 <div style={{display:"flex" , textAlign:"center" , justifyContent:"center" }}> 

 <a href='https://twitter.com/fatcatsolana'><button class="learn-more">Twitter</button></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
 <a href='https://t.me/FatCatCoinSol'><button class="learn-more">Telegram</button></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
 <a href='https://dexscreener.com/solana/4cjcxv5ihpchwfj4y8abrt3xt6gl3ehqwenk3m4kvdqr'><button class="learn-more">Chart</button></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
 <a href='https://raydium.io/swap/?inputCurrency=sol&outputCurrency=64zRGSHxDoEd7vXvgUrZPEW21sMGQbaDFoX29BG3eFi1&fixed=in'><button class="learn-more">Buy $FatCat </button></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
 </div>

 

</Grid>

                           <Grid container >
                           <Grid item md={12}  lg={12} xs={12} sm={12} >
                            <br/>   <br/>
                            <div style={{display:"flex" , textAlign:"center" , justifyContent:"center" }}> <h1 className='title'>$FAT CAT</h1></div>
                          


 

</Grid>


                           </Grid>
                           <div className='container centeritall'>
<img src={fato} className='cat'/>

       </div>
    </div>
  )
}

export default Meme