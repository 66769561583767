import React from 'react'
import { Grid } from '@mui/material'

import fato from './fats.png'

function Meme() {
  return (
    <div className='container'>
      <Grid container spacing={2}>

      <Grid item md={12}  lg={12} xs={6} sm={6} >

<div style={{display:"flex" , textAlign:"center" , justifyContent:"center" }}> 

<a href='https://twitter.com/fatcatsolana'><button class="learn-mores">Twitter</button></a>

</div>



</Grid>
<Grid item md={12}  lg={12} xs={6} sm={6} >

<div style={{display:"flex" , textAlign:"center" , justifyContent:"center" }}> 


<a href='https://t.me/FatCatCoinSol'><button class="learn-mores">Telegram</button></a>

</div>



</Grid>

<Grid item md={12}  lg={12} xs={6} sm={6} >

<div style={{display:"flex" , textAlign:"center" , justifyContent:"center" }}> 


<a href='https://dexscreener.com/solana/4cjcxv5ihpchwfj4y8abrt3xt6gl3ehqwenk3m4kvdqr'><button class="learn-mores">Chart</button></a>

</div>



</Grid>
<Grid item md={12}  lg={12} xs={6} sm={6} >

<div style={{display:"flex" , textAlign:"center" , justifyContent:"center" }}> 


<a href='https://raydium.io/swap/?inputCurrency=sol&outputCurrency=64zRGSHxDoEd7vXvgUrZPEW21sMGQbaDFoX29BG3eFi1&fixed=in'><button class="learn-mores">Buy  </button></a>
</div>



</Grid>

      </Grid>
                      
<div className='container centeritall'>
<img src={fato} className='cats'/>

       </div>
                           <Grid container >
                           <Grid item md={12}  lg={12} xs={12} sm={12} >
                            <br/>   <br/>
                            <div style={{display:"flex" , textAlign:"center" , justifyContent:"center" }}> <h1 className='titlem'>$FAT CAT</h1></div>
                          


 

</Grid>


                           </Grid>

    </div>
  )
}

export default Meme