import React from 'react';
import { MDBFooter } from 'mdb-react-ui-kit';
import Twitter from './twi.png'
import Telegram from './teleg.png'
import Rad from './rad.png'
import Bird from './image/dex.png'
export default function App() {
  return (
    <MDBFooter bgColor='' className='text-center text-lg-left backo' >


<br/>   <br/>

 <div style={{display:"flex" , textAlign:"center" , justifyContent:"center" }}> 
 <a href='https://twitter.com/fatcatsolana'><img src={Twitter} style={{width:"30px" , borderRadius:""}} /></a>&nbsp;&nbsp;&nbsp;&nbsp;
 <a href='https://t.me/FatCatCoinSol'><img src={Telegram} style={{width:"30px" , borderRadius:""}} /></a>&nbsp;&nbsp;&nbsp;&nbsp;
 <a href='https://raydium.io/swap/?inputCurrency=sol&outputCurrency=64zRGSHxDoEd7vXvgUrZPEW21sMGQbaDFoX29BG3eFi1&fixed=in'><img src={Rad} style={{width:"35px" , borderRadius:""}} /></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
 <a href='https://dexscreener.com/solana/4cjcxv5ihpchwfj4y8abrt3xt6gl3ehqwenk3m4kvdqr'><img src={Bird} style={{width:"30px" , borderRadius:""}} /></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
 </div>
 <br/>   <br/>



      <div className='text-center p-3' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)'  , color:"#fff"}}>
        &copy; {new Date().getFullYear()} Copyright:{' '}
        <a className='' href='' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)'  , color:"#fff"}}>
      Fat Cat
        </a>
      </div>
    </MDBFooter>
  );
}