import React from 'react'
import c1 from './phantom.png'
import c2 from './solana.png'
import c3 from './rads.webp'
import c4 from './deb.png'
import { Grid } from '@mui/material'
function Meme() {
  return (
    <div className='container'>
 <br/><br/>   
          <Grid container spacing={2}>
          
          <Grid item md={12} xs={12} lg={12} className='centerall'>
          <div style={{display:"flex" , textAlign:"center" , justifyContent:"center" }}> <h1 className='titlecas'>How to Buy</h1></div>
          </Grid>
 
        <Grid item md={12} xs={12} lg={12} className='centerall'>

        <div class="card shadow">
        <br/>
        <div className='container'>
        <h4 className='htb_h'>Create a Wallet</h4>
          <div className='centeritall'> <img src={c1} style={{width:"50px"}} /></div>
          <p className='htb_hp'>Download Phantom or your wallet of choice from the app store or google play store for free. Desktop users, download the google chrome extension by going to phantom.app.</p>
         

        </div>
          
</div>
        </Grid>

        <Grid item md={12} xs={12} lg={12} className='centerall'>


<div class="card shadow">
<br/>
<div className='container'>
<h4 className='htb_h'>
Get Some SOL</h4>
  <div className='centeritall'> <img src={c2} style={{width:"50px"}} /></div>
  <p className='htb_hp'>

  Have SOL in your wallet to switch to $FatCat. If you don’t have any SOL, you can buy SOL from an exchange or cross chain swap and send it to your wallet.
  </p>
 

</div>
  
</div>
</Grid>

<Grid item md={12} xs={12} lg={12} className='centerall'>


<div class="card shadow">
<br/>
<div className='container'>
<h4 className='htb_h'>
Go to Raydium</h4>
  <div className='centeritall'> <img src={c3} style={{width:"50px"}} /></div>
  <p className='htb_hp'>

  Connect to Raydium . Go raydium.io  in google chrome or on the browser inside your Phantom app. Connect your wallet. Paste the $FatCat token address into Raydium and confirm the swap. When Phantom prompts you for a wallet signature, sign.
  </p>
 

</div>
  
</div>
</Grid>


<Grid item md={12} xs={12} lg={12} className='centerall'>


<div class="card shadow">
<br/>
<div className='container'>
<h4 className='htb_h'>
Switch SOL for Fat Cat</h4>
 
  <br/>
  <p className='htb_hp'>

  Now paste Fat Cat Contract Address on Raydium and swap your Sol to $FatCat
  </p>
 

</div>
  
</div>
</Grid>
           </Grid>
          
<br/><br/>
     
    </div>
  )
}

export default Meme