import React from 'react'
import { Grid } from '@mui/material'
import Ship from './ihad.png'
function Meme() {
  return (
    <div className='container'>
           <Grid container >
                           <Grid item md={12}  lg={12} xs={12} sm={12} >
                            <br/>   <br/>
                            <div style={{display:"flex" , textAlign:"center" , justifyContent:"center" }}> <h1 className='titleca'>Contract Address</h1></div>
                            <br/>   <br/>


 
 <div style={{display:"flex" , textAlign:"center" , justifyContent:"center" }}> 
<h1 className='CA'>Ch5JJQZspiJ9MCDURZAA8nnVvbiwD1tnPcfmkCcVPiDb</h1>
 </div><br/> <br/>
</Grid>


                           </Grid>
     
    

   <img src={Ship} style={{width:"100%"}}/>
      
      
      </div>
  )
}

export default Meme