import React from 'react'
import { Grid } from '@mui/material'
import Ship from './ihad.png'
function Meme() {
  return (
    <div className='container'>
     <br/>  <br/>  <br/>
      <div className='centeritall'>
      <a href='https://raydium.io/swap/?inputCurrency=sol&outputCurrency=64zRGSHxDoEd7vXvgUrZPEW21sMGQbaDFoX29BG3eFi1&fixed=in'>  <div class="item button-parrot"  >
    <button style={{backgroundColor:"#f90074"}}>Click Me!
      <div class="parrot"></div>
      <div class="parrot"></div>
      <div class="parrot"></div>
      <div class="parrot"></div>
      <div class="parrot"></div>
      <div class="parrot"></div>
    </button>
  </div></a>

      </div>
           <Grid container >
                           <Grid item md={12}  lg={12} xs={12} sm={12} >
                           <br/><br/><br/>
                            <div style={{display:"flex" , textAlign:"center" , justifyContent:"center" }}> <h1 className='titleca'>Contract Address</h1></div>
                            <br/>   <br/>


 
 <div style={{display:"flex" , textAlign:"center" , justifyContent:"center" }}> 
<h1 className='CA'> 64zRGSHxDoEd7vXvgUrZPEW21sMGQbaDFoX29BG3eFi1</h1>
 </div><br/> <br/>
</Grid>


                           </Grid>
                         
      <br/>


      
      
      </div>
  )
}

export default Meme